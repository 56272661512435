import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SelectSearch, { fuzzySearch } from "react-select-search";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
// Dialof Import
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
// test icons
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const useStyles = makeStyles((theme) => ({
    modal: {
        height: "100%",
        width: "100%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    sizeInc: {
        width: "120%",
    },
    buttonSubmit: {
        //    marginTop:'150px'
    },
    button: {
        backgroundColor: "green",
        color: "white"
    },
    paper: {
        height: "100%",
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        marginTop: "3",
        float: "right",
    },
}));
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const CreateUsers = () => {
    const [validated, setValidated] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [ofsRole, setOfsRole] = useState('');
    const [bosRole, setBosRole] = useState('');
    const [defectRole, setDefectRole] = useState('');
    const [isOFS, setIsOFS] = useState(false);
    const [isBOS, setIsBOS] = useState(false);
    const [isDH, setIsDH] = useState(false);

    const columns = [
        { field: "username", title: "UserName" },
        { field: "email", title: "Email" },
        // {
        //     field: "isAdmin",
        //     title: "User Role",
        //     lookup: { Admin: "Admin", SuperUser: "SuperUser", User: "User" },
        // },
        {   field: "defectRole",
            title: "DH Role",
            lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
        },
        {   field: "ofsRole",
            title: "OFS Role",
            lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
        },
        {   field: "bosRole",
            title: "BOS Role",
            lookup: { Admin: "Admin", SuperUser: "Line Leader", User: "User" },
        },
        {   field: "DepartName",
            title: "Department",
        }
    ];
    //mehak edit start
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [openR, setOpenR] = React.useState(false);
    const handleOpenR = () => {
        setOpenR(true);
    };

    const handleCloseR = () => {
        setOpenR(false);
    };
    //mehak edit end

    const classes = useStyles();

    const [userData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [admin, setAdmin] = useState(3);
    const [departName, setDepartName] = useState("")

    const [userRole, setUserRole] = useState(0);

    const clearForm = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setAdmin("");
    };

    const skuData = async () => {
        const fetchedData = await axios.get(
            "https://finehhb.pakistanautomation.com.pk/api/users/getUsers"
        );
        setUsersData(fetchedData.data);
        setLoading(false);
    };

    console.log(userRole);
    useEffect(() => {
        var token = localStorage.getItem("token");
        var decoded = jwt.decode(token, { complete: true });
        const isAdmin = decoded.payload.isAdmin;
        setUserRole(isAdmin);
        skuData();
        getdepartments()
    }, []);

    console.log(userData);


    const submitHandler = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            // add validation form
            setIsDisabled(false);
            setValidated(true);
            toast.error("Please fill all fields")
            return
        }
        if (
            username.length === 0 &&
            email.length === 0 &&
            password.length === 0 &&
            admin === 3
        ) {
            // alert("please write area name")
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            setIsDisabled(false);

            return;
        }
        setValidated(true);
        setIsDisabled(true);

        console.log({
            email: email,
            username: username,
            password: password,
            isAdmin: admin,
        });
        axios
            .post(
                "https://finehhb.pakistanautomation.com.pk/api/users/userCreation",
                {
                    email: email,
                    username: username,
                    password: password,
                    departName:departName,
                    AssignedOFS:isOFS,
                    AssignedEdefect:isDH,
                    AssignedBOS:isBOS,
                    ofsRole:ofsRole,
                    bosRole:bosRole,
                    defectRole:defectRole,

                    // isAdmin: admin,

                }
            )
            .then((res) => {
                console.log("respooooo", res);
                toast.success("Inserted successfully !");
                setIsDisabled(false);
                if (res.data.results === "Success") {
                    clearForm();
                    setOpenR(false);
                    skuData();
                    setValidated(false);
                    console.log("respooooo", res);
                } else {
                    setOpenR(true);
                    console.log("respooooo", res);
                    toast.error("Error !");
                }
            })
            .catch((res) => {
                console.log(res);
                if (res.response.status === 409) {
                    // alert("User already Exist");
                    toast.error("User already Exist");
                    setIsDisabled(false);
                    return;
                } else {
                    toast.error("Error !");
                }
            });
    };
    //state for fetching data
    const [Departmentdata, setDepartmentData] = useState([]);
    //  Fetching Data For Departements
    const getdepartments = async () => {
        const fetchedData = await axios.get(
            "https://defectbe.pakistanautomation.com.pk/api/departments/getDepartment"
        );
        console.log("fetch data for department ===>", fetchedData.data);
        setDepartmentData(fetchedData.data.department);
    };
    //for select Search
    var departmentOption = Departmentdata.map((item) => ({
        name: item.department,
        value: item.department,
    }));
    console.log(ofsRole);
    return (
        <div className="p-0 m-0 container-fluid DHContainer">
            <section className="home_banner_area">
                <div className="px-4 banner_inner">
                    <div>
                        <Button
                            style={{ float: "right", marginTop: '2.5rem' }}
                            type="button"
                            onClick={handleOpenR}
                            variant="contained"
                            // color="success"
                            className={`addUserBtn ${classes.button}`}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Add Users
                        </Button>
                        <Dialog
                            fullScreen={fullScreen}
                            open={openR}
                            onClose={handleCloseR}
                            aria-labelledby="responsive-dialog-title"
                            PaperProps={{
                                style: {
                                    background: "linear-gradient(to bottom, #039BE5, #22B2F4)",
                                    width: "600px",
                                },
                            }}
                        >
                            <DialogTitle id="responsive-dialog-title">
                            <div className="test-TwoBtn">
                                        <div>
                                            {isDisabled ? (
                                                <Button type="submit" className="dialog-btn tick-btn-abc" disabled>
                                                    <CheckIcon/>
                                                </Button>
                                            ) : (
                                                <Button type="submit" onClick={submitHandler} className="dialog-btn tick-btn-abc">
                                                   <CheckIcon/>
                                                </Button>
                                            )}
                                        </div>
                                        <div>
                                            <Button
                                                className="dialog-btn close-btn-abc"
                                                onClick={() => handleCloseR()}
                                            >
                                                <CloseIcon/>
                                            </Button>
                                        </div>
                                    </div>
                                <Typography
                                    component="h1"
                                    variant="h5"
                                    className="typography text-center text-white border-bottom rounded"
                                    style={{
                                        color: "black",
                                        fontWeight: "bolder",
                                        fontFamily: "ariel",
                                    }}
                                >
                                    Create Users
                                </Typography>
                                
                            </DialogTitle>
                            <DialogContent>
                                <Form noValidate validated={validated}>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="8"
                                            className="w-100"
                                            controlId="validationCustom01"
                                        >
                                            <Form.Label>Name :</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                className="w-100"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="8"
                                            className="w-100"
                                            controlId="validationCustom02"
                                        >
                                            <Form.Label>Email :</Form.Label>
                                            <Form.Control
                                                autoComplete="off"
                                                type="email"
                                                placeholder="Email"
                                                className="w-100"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="8"
                                            className="w-100"
                                            controlId="validationCustom03"
                                        >
                                            <Form.Label>Password :</Form.Label>
                                            <Form.Control
                                                autoComplete="off"
                                                type="password"
                                                placeholder="password"
                                                className="w-100"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid password.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                                as={Col}
                                                md="8"
                                                className="w-100"
                                                controlId="validationCustom04"
                                            >
                                                <Form.Label>Depart Name :</Form.Label>
                                                {/* <Form.Control
                                                    autoComplete="off"
                                                    type="text"
                                                    placeholder="Depart Name"
                                                    className="w-100"
                                                    value={departName}
                                                    onChange={(e) => setDepartName(e.target.value)}
                                                    required
                                                /> */}
                                                <SelectSearch
                                                    id="department"
                                                    value={departName}
                                                    onChange={(e) => setDepartName(e)}
                                                    options={departmentOption}
                                                    search
                                                    filterOptions={fuzzySearch}
                                                    placeholder="Select your Area"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Depart Name.
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                        {/* Check for OFS user */}
                                        <Form.Group className="my-1">
                                            <Form.Check
                                                // required
                                                label="For OFS users"
                                               
                                                // feedbackType="invalid"
                                                onChange={(e) => setIsOFS(preValue => !preValue)}
                                            />
                                        </Form.Group>

                                        {/* {isOFS ?
                                            <Form.Group
                                                as={Col}
                                                md="8"
                                                className="w-100"
                                                controlId="validationCustom04"
                                            >
                                                <Form.Label>Depart Name :</Form.Label>

                                                <SelectSearch
                                                    id="department"
                                                    value={departName}
                                                    onChange={(e) => setDepartName(e)}
                                                    options={departmentOption}
                                                    search
                                                    filterOptions={fuzzySearch}
                                                    placeholder="Select your Area"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Depart Name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            :
                                            ""
                                        } */}
                                        {/* For defect handling user */}
                                        <Form.Group className="my-1">
                                            <Form.Check
                                                // required
                                                label="For Defect Handling users"
                                               
                                                // feedbackType="invalid"
                                                onChange={(e) => setIsDH(preValue => !preValue)}
                                            />
                                        </Form.Group>
                                        {/* For BOS user */}
                                        <Form.Group className="my-1">
                                            <Form.Check
                                                // required
                                                label="For BOS users"
                                               
                                                // feedbackType="invalid"
                                                onChange={(e) => setIsBOS(preValue => !preValue)}
                                            />
                                        </Form.Group>


                                        {/* <div>
                                            <FormControl
                                                required
                                                className={`w-100 ${classes.formControl}`}
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-label"
                                                    className="text-dark"
                                                >
                                                    Admin?
                                                </InputLabel>
                                                <Select
                                                    className={classes.sizeInc}
                                                    className={`w-100 ${classes.sizeInc}`}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={admin}
                                                    onChange={(e) => setAdmin(e.target.value)}
                                                >
                                                    <MenuItem value={"Admin"}>Admin</MenuItem>
                                                    <MenuItem value={"SuperUser"}>SuperUser</MenuItem>
                                                    <MenuItem value={"User"}>User</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div> */}
                                        <hr/>
                                        <div className='UserRoleRadio'>
                                            <div>
                                                <div><Form.Label>OFS Role :</Form.Label></div>
                                            </div>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio1" defaultValue="Admin" onChange={(e) => setOfsRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Admin</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio2" defaultValue="SuperUser" onChange={(e) => setOfsRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">Line Leader</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio3" defaultValue="User" onChange={(e) => setOfsRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio3">User</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='UserRoleRadio'>
                                            <div>
                                                <div><Form.Label>BOS Role :</Form.Label></div>
                                            </div>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio4" defaultValue="Admin" onChange={(e) => setBosRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Admin</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio5" defaultValue="SuperUser" onChange={(e) => setBosRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">Line Leader</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio6" defaultValue="User" onChange={(e) => setBosRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio3">User</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='UserRoleRadio'>
                                            <div>
                                                <div><Form.Label>Defect Role :</Form.Label></div>
                                            </div>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio7" defaultValue="Admin" onChange={(e) => setDefectRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Admin</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio8" defaultValue="SuperUser" onChange={(e) => setDefectRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">Line Leader</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio9" defaultValue="User" onChange={(e) => setDefectRole(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="inlineRadio3">User</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </Row>
                                    {/* <Form.Group className="mb-3">
                                        <Form.Check
                                            required
                                            label="For OFS users"
                                           
                                            feedbackType="invalid"
                                            onChange={(e)=> setIsOFS(preValue => !preValue)}
                                        />
                                    </Form.Group> */}
                                </Form>
                            </DialogContent>
                        </Dialog>
                        {/* Form Ended */}
                    </div>
                    {/* <div className="w-100"> */}
                    <div className="w-100" style={{ marginTop: '5rem' }}>
                        <MaterialTable
                            icons={tableIcons}
                            style={{
                                marginTop: "10px",
                                whiteSpace: "nowrap",
                                maxWidth: "150%",
                                tableLayout: "fixed",
                                backgroundColor: "#2a8ea5ad",
                                color: "black",
                            }}
                            title="Users"
                            columns={columns}
                            data={userData}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            const dataUpdate = [...userData];
                                            const index = oldData.id;
                                            dataUpdate[index] = newData;
                                            // console.log(newData);
                                            axios
                                                .post(
                                                    "https://finehhb.pakistanautomation.com.pk/api/users/updateUser",
                                                    newData
                                                )
                                                .then((res) => {
                                                    if (res.data.results === "Success") {
                                                        console.log("updatedSuccessfully");
                                                        console.log(newData);
                                                        skuData();
                                                        toast.success("Update successfully !");
                                                    } else {
                                                        console.log(res.data.results);
                                                        console.log(newData);
                                                        toast.error("Error !");
                                                    }
                                                });
                                            // setCauseData([...dataUpdate]);
                                            resolve();
                                        }, 1000);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            // const dataDelete = [...causeData];
                                            const index = oldData.id;
                                            axios
                                                .post(
                                                    "https://finehhb.pakistanautomation.com.pk/api/users/deleteUser",
                                                    {
                                                        id: index,
                                                    }
                                                )
                                                .then((res) => {
                                                    if (res.data.results === "Success") {
                                                        console.log("updatedSuccessfully");
                                                        // console.log(newData);
                                                        skuData();
                                                        toast.info("Delete successfully !");
                                                    } else {
                                                        console.log(res.data.results);
                                                        // console.log(newData);
                                                        toast.error("Error !");
                                                    }
                                                });

                                            resolve();
                                        }, 1000);
                                    }),
                            }}
                            options={{
                                rowStyle: rowData => {
                                    if (rowData.isAdmin === "Admin") {
                                        return { background: 'linear-gradient(to right, #3A94A6 , #00dbde)' };
                                    }

                                    return {};
                                },
                                filtering: true,
                                exportButton: true,
                                // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
                                headerStyle: {
                                    backgroundColor: "#2E3B55",
                                    color: "white",
                                },
                            }}
                        />
                        {/* {userRole == "Admin" ? (
          loading ? (
            <div className="skeletonAdjust">
              <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
                <Skeleton count={8} duration={1} />
              </SkeletonTheme>
            </div>
          ) : (
            <MaterialTable
              icons={tableIcons}
              style={{
                marginTop: "10px",
                whiteSpace: "nowrap",
                maxWidth: "150%",
                tableLayout: "fixed",
              }}
              title="Users"
              columns={columns}
              data={userData}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...userData];
                      const index = oldData.id;
                      dataUpdate[index] = newData;
                      // console.log(newData);
                      axios
                        .post(
                          "https://finehhb.pakistanautomation.com.pk/api/users/updateUser",
                          newData
                        )
                        .then((res) => {
                          if (res.data.results === "Success") {
                            console.log("updatedSuccessfully");
                            console.log(newData);
                            skuData();
                            toast.success("Update successfully !");
                          } else {
                            console.log(res.data.results);
                            console.log(newData);
                            toast.error("Error !");
                          }
                        });
                      // setCauseData([...dataUpdate]);
                      resolve();
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // const dataDelete = [...causeData];
                      const index = oldData.id;
                      // console.log(index)
                      // console.log(oldData);
                      // dataDelete.splice(index, 1);
                      // setCauseData([...dataDelete]);
                      axios
                        .post(
                          "https://finehhb.pakistanautomation.com.pk/api/users/deleteUser",
                          {
                            id: index,
                          }
                        )
                        .then((res) => {
                          if (res.data.results === "Success") {
                            console.log("updatedSuccessfully");
                            // console.log(newData);
                            skuData();
                            toast.info("Delete successfully !");
                          } else {
                            console.log(res.data.results);
                            // console.log(newData);
                            toast.error("Error !");
                          }
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              // actions={[
              //     {

              //         icon: 'save',
              //         tooltip: 'Save User',

              //         icon: () => <EditIcon style={{ color: 'black' }} />,
              //         tooltip: "Modification in process...",
              //         //   onClick: (event, rowData) => handleOpenForm(rowData)
              //     },
              // ]}
              options={{
                filtering: true,
                exportButton: true,
                // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                headerStyle: {
                  backgroundColor: "#2E3B55",
                  color: "white",
                },
              }}
            />
          )
        ) : (
          <MaterialTable
            icons={tableIcons}
            actions={[
              {
                disabled: true,
                icon: "edit",
                tooltip: "Edit Team",
                onClick: (event, rowData) => {
                  // Do save operation
                },
              },
              {
                disabled: true,
                icon: "delete",
                tooltip: "Delete Team",
                onClick: (event, rowData) => {
                  // Do save operation
                },
              },
            ]}
            style={{
              marginTop: "10px",
              whiteSpace: "nowrap",
              maxWidth: "150%",
              tableLayout: "fixed",
            }}
            title="Team"
            columns={columns}
            data={userData}
            options={{
              filtering: true,
              exportButton: true,
              // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
              headerStyle: {
                backgroundColor: "#2E3B55",
                color: "white",
              },
            }}
          />
        )} */}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CreateUsers;
