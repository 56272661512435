import React, { useState, useEffect } from 'react'
import Home from './Home'
import { Navbar, Container, Offcanvas, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import {
    Switch,
    Route,
    Link,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import jwt from "jsonwebtoken";
import UserProfile from './profile/UserProfile'
import CreateUsers from './CreateUsers';

export const LandingPage = () => {
    const [expanded, setExpanded] = useState(false);
    const [userRoleO, setUserRoleO] = useState('')
    const [userRoleB, setUserRoleB] = useState('')
    const [userRoleD, setUserRoleD] = useState('')
    const match = useRouteMatch();
    const history = useHistory()


    const HandleLogout = () => {
        localStorage.removeItem('username')
        localStorage.removeItem('token')
        if (localStorage.getItem('token')) {
            console.log('here')
        }
        else {
            history.push('/signin')
        }
    }
    useEffect(() => {
        var token = localStorage.getItem("token");
        console.log("token:" + token);
        var decoded = jwt.decode(token, { complete: true });
        console.log(decoded);
        const isAdminO = decoded.payload.ofsRole;
        const isAdminB = decoded.payload.bosRole;
        const isAdminD = decoded.payload.defectRole;


        setUserRoleO(isAdminO);
        setUserRoleB(isAdminB);
        setUserRoleD(isAdminD);
    }, [])
    console.log(userRoleD);
    console.log(userRoleO);
    console.log(userRoleB);

    return (
        <div className="main-container" style={{ height: '100vh' }}>
            <header className="header_area">
                <div className="main_menu" id="mainNav">
                    {/* <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <div className='mt-1'>
                                <Link to={{ pathname: "https://www.finehh.com/" }} target='_blank' className="navbar-brand logo_h" >
                                    <img
                                        className="logo_h"
                                        src="./icon/logo.png" width='250' alt="" data-pagespeed-url-hash={4282161037}
                                    />
                                </Link>
                            </div>
                            <div>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" />
                                </button>
                                <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                    <ul className="nav navbar-nav menu_nav ml-auto">
                                        <li className="nav-item active ">
                                            <Link className="nav-link active" to="/home">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={match.url + "/createUsers"}>Create Users</Link>
                                           
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={match.url + "/accountDetails"}>My Profile</Link>
                                           
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className="nav-link"
                                                to=''
                                                onClick={HandleLogout}
                                            >
                                                Logout</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav> */}

                    <Navbar bg="transparent" expand={false} collapseOnSelect expanded={expanded}>

                        <Container fluid>
                            <Navbar.Brand >
                                <Link to={{ pathname: "https://www.finehh.com/" }} target='_blank' className="navbar-brand logo_h" >
                                    <img
                                        className="logo_h"
                                        src="./icon/logo.png" width='250' alt="" data-pagespeed-url-hash={4282161037}
                                    />
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setExpanded(expanded ? false : "expanded")} />
                            <Navbar.Offcanvas
                                id="offcanvasNavbar"
                                aria-labelledby="offcanvasNavbarLabel"
                                placement="end"
                            >

                                <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>

                                    <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>

                                </Offcanvas.Header>

                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3">
                                        <Nav.Link><Link onClick={() => setExpanded(false)} className="nav-link active main_btn" to="/home">Home</Link></Nav.Link>
                                        {/* {
                                           userRoleO == 'Admin' ? userRoleB == 'Admin' ? userRoleD == 'Admin'? 
                                           <Nav.Link> <Link onClick={() => setExpanded(false)} className="nav-link main_btn" to={match.url + "/createUsers"}>Create Users</Link></Nav.Link>
                                           : null : null : null
                                       }
                                       {
                                           userRoleO == 'Admin' ? userRoleB == 'Admin' ? userRoleD == 'Admin'? 
                                           <Nav.Link> <Link onClick={() => setExpanded(false)} className="nav-link main_btn" to={match.url + "/createUsers"}>Create Users</Link></Nav.Link>
                                           : null : null : null
                                       }
                                       {
                                           userRoleO == 'Admin' ? 
                                           <Nav.Link> <Link onClick={() => setExpanded(false)} className="nav-link main_btn" to={match.url + "/createUsers"}>Create Users</Link></Nav.Link>
                                           : null 
                                       } */}

                                        {
                                            userRoleO == 'Admin' || userRoleB == "Admin" || userRoleD == "Admin" ?
                                                <Nav.Link> <Link onClick={() => setExpanded(false)} className="nav-link main_btn" to={match.url + "/createUsers"}>Create Users</Link></Nav.Link>
                                                : null
                                        }

                                        <Nav.Link> <Link onClick={() => setExpanded(false)} className="nav-link main_btn" to={match.url + "/accountDetails"}>My Profile</Link></Nav.Link>
                                        <span
                                            className="nav-link submit_btn "
                                            style={{ cursor: 'pointer', marginTop: '5rem' }}
                                            onClick={HandleLogout}
                                        >
                                            Logout
                                        </span>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                </div>
            </header>

            <Switch>
                <Route exact path={"/home"}>
                    <Home />
                </Route>
                <Route path={match.url + "/accountDetails"}>
                    <UserProfile />
                </Route>
                <Route path={match.url + "/createUsers"}>
                    <CreateUsers />
                </Route>
            </Switch>
            {/* ) : ( */}
            {/* <Switch> */}
            {/* <Route path={"/home"} component={Home} /> */}
            {/* <Route path={"/accountDetails"} component={UserProfile} /> */}
            {/* <Route path={match.url + "/accountDetails"} component={UserProfile} /> */}
            {/* </Switch> */}
            {/* )} */}
        </div>
    )

}
