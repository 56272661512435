import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignIn from "./SignIn";
import { LandingPage } from "./components/LandingPage.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateUsers from "./components/CreateUsers";
import UserProfile from "./components/profile/UserProfile";

// var CrossStorageClient = require("cross-storage").CrossStorageClient;
// var CrossStorageHub = require("cross-storage").CrossStorageHub;

function App() {
  // Limit requests to any client running on .localhost:300x
  // CrossStorageHub.init([
    // { origin: /.*localhost:300\d$/, allow: ["get", "set", "del"] },
  // ]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignIn}></Route>
          <Route path="/signin" component={SignIn}></Route>
          <Route path="/home" component={LandingPage}></Route>
          {/* <Route path="/createUser" component={CreateUsers}></Route> */}
          {/* <Route path="/accountDetails" component={UserProfile}></Route> */}
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
