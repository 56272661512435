import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/paper";
import Axios from "axios";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "./Loader";
function Copyright() {
  return (
    <div style={{ marginBottom: "0.2rem" }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Powered By "}
        <Link href="https://www.facebook.com/PakistanAutomation">
          <span style={{ color: "rgb(124,204,191)" }}>PAKISTAN AUTOMATION</span>
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    marginTop: -15,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    border: "1px solid",
    backgroundColor: "rgb(124,204,191)",
    borderRadius: "5rem",
    width: "50%",
    color: "white",
    padding: "0.5rem",
    marginTop: "2rem",
    textAlign: "center",
    fontSize: "100%",
    position: "relative",
  },
}));

export default function SignIn() {
  // // Cross-Domain Work

  // console.clear()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const url = "https://finehhb.pakistanautomation.com.pk/api/users/login";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [checkError, setCheckError] = useState(false);

  console.log("is Login before", isLogin);
  function onSubmit(e) {
    setIsLogin(true);
    setTimeout(() => {
      setIsLogin(false);
    }, 3000);
    Axios.post(url, {
      email: email,
      password: password,
    })
      .then((res) => {
        setLoading(false);
        console.log("abc", isLogin);
        console.log(res);
        localStorage.setItem("token", res.data.token);
        if (res.status === 201) {
          toast.success("Login successfully");
        }
        if (localStorage.getItem("token")) {
          history.push("/home");
        } else {
          setIsLogin(false);
        }
        console.log("No user found");
      })
      .catch((err) => {
        console.log("in catch", isLogin);
        setCheckError(true);
        if (err.status === 401) {
          toast.error("Authfailed in bcr wrong password");
        } else if (err.status === 404) {
          toast.error("User not found some issues in authentication");
        }
        toast.error(" Authentication failed.");
        setIsLogin(false);
      });
  }
  const paperStyle = {
    padding: 20,
    height: "80vh",
    width: 300,
    margin: "20px auto",
  };
  return (
    <>
      <div>
        {!loading ? (
          !checkError ? (
            <div style={{ backgroundColor: "#050115", color: "#d5fff7" }}>
              <div id="building" style={{ fontSize: "12px" }}>
                <div id="blocks">
                  <div className="b" id="b1"></div>
                  <div className="b" id="b2"></div>
                  <div className="b" id="b3"></div>
                  <div className="b" id="b4"></div>
                </div>
                <div id="caption">Signing You In...</div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <Link to="/"></Link>
        )}
      </div>
      <div className="bg back-ground-color">
        <Grid className="login-paper">
          <Paper
            elevation={10}
            style={paperStyle}
            className="paper-style-web resp-for-tab"
          >
            <div className="resp-form-container">
              <div align="center" className="Form-Icon">
                <LockOpenIcon
                  style={{ color: "rgb(124,204,191)", fontSize: "40" }}
                  className="resp-for-mobile"
                />

                <h2
                  style={{
                    color: "rgb(124,204,191)",
                    textAlign: "center",
                    display: "table-cell",
                  }}
                  className="resp-for-mobile"
                >
                  Sign In
                </h2>
              </div>
              <form
                className="SignIn-Form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="Form-label">
                  <label>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgb(124,204,191)",
                      }}
                    >
                      E-mail
                    </span>
                  </label>
                </div>
                <div className="col-3 m-0 " style={{ width: "100%" }}>
                  <input
                    {...register("email", { pattern: /^\S+@\S+$/i })}
                    className="effect-19 w-100 resp-input input"
                    type="text"
                    placeholder="E-mail Address"
                    value={email}
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Email format is Wrong",
                      },
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <label>Email</label> */}
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
                {errors?.email?.type === "pattern" && (
                  <p style={{ color: "#F73430", fontWeight: "normal" }}>
                    * Email format is wrong
                  </p>
                )}

                <div className="Form-label">
                  <label>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgb(124,204,191)",
                      }}
                    >
                      Password
                    </span>
                  </label>
                </div>
                <div
                  className="col-3 input-effect m-0 mb-3"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // padding: "1rem",
                  }}
                >
                  <input
                    required
                    className="effect-19 w-100 resp-input input"
                    type="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                  />
                  {/* <label>Password</label> */}
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
                {isLogin ? (
                  <div className="w-100 my-3">
                    <div className="w-25 mx-auto">
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`w-100 ${classes.submit} my-3`}
                  >
                    Sign In
                  </button>
                )}
              </form>
              {/* <Loader /> */}

              <Box mt={5}>
                <Copyright />
              </Box>
            </div>
          </Paper>
          {/* )} */}
        </Grid>
      </div>
    </>
  );
}
