import React from 'react'
import {
    Link,
} from "react-router-dom";

const Home = () => {
    return (
        <div>
            
            <section className="home_banner_area" id="home">

                <div className="main-container overflow-auto banner_inner">
                    <div className="container" style={{ display: 'flex', flexDirection: "column" }}>
                        <div className="main_title">
                            <h2 style={{color:'black'}}>Fine Hygienic Holding</h2>
                            <h3 style={{fontFamily:'cursive'}}>Digital Store</h3>
                            {/* <p>Fine Hygienic Holding, is a wellness company with dual headquarters in Amman, Jordan and Dubai, UAE, founded in 1958 by Elia Nuqul. Its current CEO is James Michael Lafferty. */}
                            {/* </p> */}
                        </div>
                        <div className="feature_inner row">
                            <div className="col-lg-3 col-md-6 hvr-shadow-radial py-2 ">
                                <div className="feature_item"
                                    style={{ background: "linear-gradient(100deg, #5AD0F2, #2DBDFA)" }}
                                >
                                    {/* <img src="data:image/webp;base64,UklGRiQCAABXRUJQVlA4TBgCAAAvO8AOEF8EKZIkR5LHLKnjT2hF7/c2T5BwEMBt3Ejqg2P8sQ1A0tY6A2nbZPev+WXUNpIkF382MxxOOrv/+oEDMep5AOA8APAq4HrzLOA8gIryueE5Ow3vBQAAJAD43DCvlz5uT17ffN/sYOFsGq4PULmfPD/eiyYPRuilSaVyfTQ0RCoND40SqYBKACqgAgIlQAIQgF/9h8lPukNI4FXYmlzSkPO7wSzMCDMLzIIwzwKzMMMsMAvMjDDDM8PMMP+aYMjXCAl7C7CSRzpD+vX/CUi3rR1v3psPSW3bVlDbtmKzthHU/7p5R+8zzCSi/2rctnG4XZ1S7r7A2Hci9vT4cH93e3N9dXlxfnZ6cnwUCYeCAb/P63G7nCOjY+MTk1PTM7Nz8wuLS8srq2vrGwdmO2Ps8/XlmcLm1raV/b7R2XF8ULJEKe2/U9pNiejL03NMOf0EmtDIaUO7cD35ALIM/xSo4gEADL5sQAJyB0UP8pEX8BsBFAMQrgvdLZkN3lYA9RQXNQKo9tSiRPyx/HBIA4OCKhP+hNMhLZSBmkKgSHxM9gIIBSVAkVAsPp470VGgD0go9EgU05HmZDxLqHJLKKWJZ79PQpFLQhlRPHuT0xLKSeLZ5+WmZVSIj2eubnDTMiqJag43LaOOqF5x0wBZrZMhI53MgArtMGGNHaLtR6kuUUp7tH+cH9K/Fe2fjrGvRJzCodnGGA==" alt="" data-pagespeed-url-hash={508960686} onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> */}
                                    <h4 className="text-Card">OFS</h4>
                                    <p style={{textAlign:"center",fontSize:'15px', height: '10vh'}}>Ongoing Feedback System
                                    </p>
                                    <Link target='_blank' to={{ pathname: `https://ofs.pakistanautomation.com.pk/?token=${localStorage.getItem('token')}`}} className="main_btn mt-4 text-decoration-none">Explore</Link>

                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 hvr-shadow-radial py-2 ">
                                <div className="feature_item "   
                                    style={{ background: "linear-gradient(100deg, #FF66F4, #7D7AF8)" }}
                                >
                                    {/* <img src="data:image/webp;base64,UklGRiQCAABXRUJQVlA4TBgCAAAvO8AOEF8EKZIkR5LHLKnjT2hF7/c2T5BwEMBt3Ejqg2P8sQ1A0tY6A2nbZPev+WXUNpIkF382MxxOOrv/+oEDMep5AOA8APAq4HrzLOA8gIryueE5Ow3vBQAAJAD43DCvlz5uT17ffN/sYOFsGq4PULmfPD/eiyYPRuilSaVyfTQ0RCoND40SqYBKACqgAgIlQAIQgF/9h8lPukNI4FXYmlzSkPO7wSzMCDMLzIIwzwKzMMMsMAvMjDDDM8PMMP+aYMjXCAl7C7CSRzpD+vX/CUi3rR1v3psPSW3bVlDbtmKzthHU/7p5R+8zzCSi/2rctnG4XZ1S7r7A2Hci9vT4cH93e3N9dXlxfnZ6cnwUCYeCAb/P63G7nCOjY+MTk1PTM7Nz8wuLS8srq2vrGwdmO2Ps8/XlmcLm1raV/b7R2XF8ULJEKe2/U9pNiejL03NMOf0EmtDIaUO7cD35ALIM/xSo4gEADL5sQAJyB0UP8pEX8BsBFAMQrgvdLZkN3lYA9RQXNQKo9tSiRPyx/HBIA4OCKhP+hNMhLZSBmkKgSHxM9gIIBSVAkVAsPp470VGgD0go9EgU05HmZDxLqHJLKKWJZ79PQpFLQhlRPHuT0xLKSeLZ5+WmZVSIj2eubnDTMiqJag43LaOOqF5x0wBZrZMhI53MgArtMGGNHaLtR6kuUUp7tH+cH9K/Fe2fjrGvRJzCodnGGA==" alt="" data-pagespeed-url-hash={508960686} onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> */}
                                    <h4 className="text-Card">BOS</h4>
                                    <p style={{textAlign:"center",fontSize:'15px', height: '10vh'}}>Behaviour Observation System
                                    </p>
                                    <Link target='_blank'  to={{ pathname: `https://bos.pakistanautomation.com.pk/?token=${localStorage.getItem('token')}`}} className="main_btn mt-4 text-decoration-none">Explore</Link>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 hvr-shadow-radial py-2 defect-handling-card">
                                <div className="feature_item"
                                    style={{ background: "linear-gradient(100deg, #01F4EE, #01E2AD)" }}
                                >
                                    {/* <img src="data:image/webp;base64,UklGRiQCAABXRUJQVlA4TBgCAAAvO8AOEF8EKZIkR5LHLKnjT2hF7/c2T5BwEMBt3Ejqg2P8sQ1A0tY6A2nbZPev+WXUNpIkF382MxxOOrv/+oEDMep5AOA8APAq4HrzLOA8gIryueE5Ow3vBQAAJAD43DCvlz5uT17ffN/sYOFsGq4PULmfPD/eiyYPRuilSaVyfTQ0RCoND40SqYBKACqgAgIlQAIQgF/9h8lPukNI4FXYmlzSkPO7wSzMCDMLzIIwzwKzMMMsMAvMjDDDM8PMMP+aYMjXCAl7C7CSRzpD+vX/CUi3rR1v3psPSW3bVlDbtmKzthHU/7p5R+8zzCSi/2rctnG4XZ1S7r7A2Hci9vT4cH93e3N9dXlxfnZ6cnwUCYeCAb/P63G7nCOjY+MTk1PTM7Nz8wuLS8srq2vrGwdmO2Ps8/XlmcLm1raV/b7R2XF8ULJEKe2/U9pNiejL03NMOf0EmtDIaUO7cD35ALIM/xSo4gEADL5sQAJyB0UP8pEX8BsBFAMQrgvdLZkN3lYA9RQXNQKo9tSiRPyx/HBIA4OCKhP+hNMhLZSBmkKgSHxM9gIIBSVAkVAsPp470VGgD0go9EgU05HmZDxLqHJLKKWJZ79PQpFLQhlRPHuT0xLKSeLZ5+WmZVSIj2eubnDTMiqJag43LaOOqF5x0wBZrZMhI53MgArtMGGNHaLtR6kuUUp7tH+cH9K/Fe2fjrGvRJzCodnGGA==" alt="" data-pagespeed-url-hash={508960686} onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> */}
                                    <h4 className="text-Card">DH</h4>
                                    <p style={{textAlign:"center",fontSize:'15px', height: '10vh'}}>Defect Handling System
                                    </p>

                                    <Link target='_blank' to={{ pathname: `https://edefect.pakistanautomation.com.pk/?token=${localStorage.getItem('token')}`}}  className="main_btn mt-4 text-decoration-none">Explore</Link>

                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-6 hvr-shadow-radial py-2">
                                <div className="feature_item"
                                    style={{ background: "linear-gradient(100deg, #FD6F2E, #F42474)" }}
                                >
                                    {/* <img src="data:image/webp;base64,UklGRiQCAABXRUJQVlA4TBgCAAAvO8AOEF8EKZIkR5LHLKnjT2hF7/c2T5BwEMBt3Ejqg2P8sQ1A0tY6A2nbZPev+WXUNpIkF382MxxOOrv/+oEDMep5AOA8APAq4HrzLOA8gIryueE5Ow3vBQAAJAD43DCvlz5uT17ffN/sYOFsGq4PULmfPD/eiyYPRuilSaVyfTQ0RCoND40SqYBKACqgAgIlQAIQgF/9h8lPukNI4FXYmlzSkPO7wSzMCDMLzIIwzwKzMMMsMAvMjDDDM8PMMP+aYMjXCAl7C7CSRzpD+vX/CUi3rR1v3psPSW3bVlDbtmKzthHU/7p5R+8zzCSi/2rctnG4XZ1S7r7A2Hci9vT4cH93e3N9dXlxfnZ6cnwUCYeCAb/P63G7nCOjY+MTk1PTM7Nz8wuLS8srq2vrGwdmO2Ps8/XlmcLm1raV/b7R2XF8ULJEKe2/U9pNiejL03NMOf0EmtDIaUO7cD35ALIM/xSo4gEADL5sQAJyB0UP8pEX8BsBFAMQrgvdLZkN3lYA9RQXNQKo9tSiRPyx/HBIA4OCKhP+hNMhLZSBmkKgSHxM9gIIBSVAkVAsPp470VGgD0go9EgU05HmZDxLqHJLKKWJZ79PQpFLQhlRPHuT0xLKSeLZ5+WmZVSIj2eubnDTMiqJag43LaOOqF5x0wBZrZMhI53MgArtMGGNHaLtR6kuUUp7tH+cH9K/Fe2fjrGvRJzCodnGGA==" alt="" data-pagespeed-url-hash={508960686} onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> */}
                                    <h4 className="text-Card">CL</h4>
                                    <p style={{textAlign:"center",fontSize:'15px', height: '10vh'}}>Center Line  System
                                     </p>
                                    {/* <Link to={{ pathname: '' }} className="main_btn_disabled mt-4 text-decoration-none">Explore</Link> */}
                                    <Link to={{ pathname: '' }} className="main_btn_disabled mt-4 text-decoration-none" disabled>Upcoming</Link>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 hvr-shadow-radial py-2">
                                <div className="feature_item"
                                    // style={{ background: "linear-gradient(100deg, #FD6F2E, #F42474)" }}
                                    style={{ background: "linear-gradient(135deg, #FFE500 0%, #01E2AD 100%)" }}
                                >
                                    {/* <img src="data:image/webp;base64,UklGRiQCAABXRUJQVlA4TBgCAAAvO8AOEF8EKZIkR5LHLKnjT2hF7/c2T5BwEMBt3Ejqg2P8sQ1A0tY6A2nbZPev+WXUNpIkF382MxxOOrv/+oEDMep5AOA8APAq4HrzLOA8gIryueE5Ow3vBQAAJAD43DCvlz5uT17ffN/sYOFsGq4PULmfPD/eiyYPRuilSaVyfTQ0RCoND40SqYBKACqgAgIlQAIQgF/9h8lPukNI4FXYmlzSkPO7wSzMCDMLzIIwzwKzMMMsMAvMjDDDM8PMMP+aYMjXCAl7C7CSRzpD+vX/CUi3rR1v3psPSW3bVlDbtmKzthHU/7p5R+8zzCSi/2rctnG4XZ1S7r7A2Hci9vT4cH93e3N9dXlxfnZ6cnwUCYeCAb/P63G7nCOjY+MTk1PTM7Nz8wuLS8srq2vrGwdmO2Ps8/XlmcLm1raV/b7R2XF8ULJEKe2/U9pNiejL03NMOf0EmtDIaUO7cD35ALIM/xSo4gEADL5sQAJyB0UP8pEX8BsBFAMQrgvdLZkN3lYA9RQXNQKo9tSiRPyx/HBIA4OCKhP+hNMhLZSBmkKgSHxM9gIIBSVAkVAsPp470VGgD0go9EgU05HmZDxLqHJLKKWJZ79PQpFLQhlRPHuT0xLKSeLZ5+WmZVSIj2eubnDTMiqJag43LaOOqF5x0wBZrZMhI53MgArtMGGNHaLtR6kuUUp7tH+cH9K/Fe2fjrGvRJzCodnGGA==" alt="" data-pagespeed-url-hash={508960686} onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> */}
                                    <h4 className="text-Card">CIL</h4>
                                    <p style={{textAlign:"center",fontSize:'15px', height: '10vh'}}>Cleaning Inspection Lubrication
                                     </p>
                                    <Link to={{ pathname: '' }} className="main_btn_disabled mt-4 text-decoration-none">Upcoming</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Home
